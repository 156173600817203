import _ from 'lodash';

import type {
  StyleParams,
  TPAPublicData,
} from '@wix/editor-platform-sdk-types';
import type { IWixStyleFont, IWixStyleParams } from '@wix/yoshi-flow-editor';

export default function (
  styleParams: IWixStyleParams | StyleParams,
  publicData: TPAPublicData,
) {
  const fonts = (styleParams.fonts as Record<string, IWixStyleFont>) || {};
  const numbers = (styleParams.numbers as Record<string, number>) || {};

  migrate('groupTitleFont', 'mobileGroupTitleFontSize');
  migrate('postsTextFont', 'mobilePostsTextFontSize');
  migrate('listTitleFont', 'mobileListTitleFontSize');
  migrate('groupSubtitleFont', 'mobileGroupSubtitleFontSize');
  migrate('tabsFont', 'mobileTabsFontSize');
  migrate('groupNameFont', 'mobileGroupNameFontSize');
  migrate('groupInfoFont', 'mobileGroupInfoFontSize');

  return {
    fonts: _.omit(fonts, Object.keys(styleParams.fonts || {})),
  };

  function migrate(key: string, legacy: string) {
    if (!fonts[key] || !numbers[legacy] || fonts[key + '▶︎m'] !== undefined) {
      return;
    }

    fonts[key + '▶︎m'] = {
      ...fonts[key],
      size: numbers[legacy],
    };
  }
}
