import _ from 'lodash';

import type {
  StyleParams,
  TPAPublicData,
} from '@wix/editor-platform-sdk-types';
import type { IWixStyleParams } from '@wix/yoshi-flow-editor';

export default function (
  styleParams: IWixStyleParams | StyleParams,
  publicData: TPAPublicData,
) {
  const { COMPONENT: settings = {} } = publicData;
  const numbers = (styleParams.numbers as Record<string, number>) || {};

  migrate('imagesPerRow', 'mediaImagesPerRow');

  return {
    numbers: _.omit(numbers, Object.keys(styleParams.numbers || {})),
  };

  function migrate(legacy: string, key = legacy) {
    if (!settings[legacy] || numbers[key] !== undefined) {
      return;
    }

    numbers[key] = settings[legacy] as number;
  }
}
