import _ from 'lodash';

import type {
  StyleParams,
  TPAPublicData,
} from '@wix/editor-platform-sdk-types';
import type { IWixStyleParams } from '@wix/yoshi-flow-editor';

import {
  EImageCrop,
  EAlignment,
  EImageRatio,
  EImageLayout,
  EMediaLayout,
  ECoverImageLayout,
} from '../constants';

const $Alignment$ = {
  left: EAlignment.Left,
  right: EAlignment.Right,
  center: EAlignment.Center,
  Left: EAlignment.Left,
  Right: EAlignment.Right,
  Center: EAlignment.Center,
};

const $ImageRatio$ = {
  square: EImageRatio.Square,
  rectangle: EImageRatio.Rectangle,
};

const $ImageCrop$ = {
  FIT: EImageCrop.Fit,
  CROP: EImageCrop.Crop,
};

const $ImageLayout$ = {
  RTL: EImageLayout.RTL,
  LTR: EImageLayout.LTR,
  MIXED: EImageLayout.Mixed,
};

const $CoverImageLayout$ = {
  thumbnail: ECoverImageLayout.Thumbnail,
  large: ECoverImageLayout.Large,
  blank: ECoverImageLayout.Blank,
};

const $MediaLayout$ = {
  grid: EMediaLayout.Grid,
  masonry: EMediaLayout.Masonry,
  collage: EMediaLayout.Collage,
};

export default function (
  styleParams: IWixStyleParams | StyleParams,
  publicData: TPAPublicData,
) {
  const { COMPONENT: settings = {} } = publicData;

  const numbers = (styleParams.numbers as Record<string, number>) || {};

  migrate($Alignment$, 'headerAlignment');
  migrate($Alignment$, 'sideBarAlignment');
  migrate($Alignment$, 'textAlignment', 'itemTextAlignment');

  migrate($ImageCrop$, 'imageCrop');
  migrate($ImageRatio$, 'imageRatio');
  migrate($ImageLayout$, 'imageLayout');

  migrate($MediaLayout$, 'mediaLayout');

  migrate($CoverImageLayout$, 'coverImageLayout');

  // save only new keys that are not undefined
  return {
    numbers: _.omit(numbers, Object.keys(styleParams.numbers || {})),
  };

  function migrate(
    dictionary: Record<string, number>,
    legacy: string,
    key = legacy,
  ) {
    link(legacy, key, dictionary);
    link(`${legacy}▶︎m`, `${key}▶︎m`, dictionary);
    link(`mobile${_.capitalize(legacy)}`, `${key}▶︎m`, dictionary);
  }

  function link(
    legacy: string,
    key: string,
    dictionary: Record<string, number>,
  ) {
    // if target exists or legacy doesn't - skip
    if (numbers[key] !== undefined || settings[legacy] === undefined) {
      return;
    }

    numbers[key] = dictionary[settings[legacy] as keyof typeof dictionary];
  }
}
