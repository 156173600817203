import _ from 'lodash';

import type {
  StyleParams,
  TPAPublicData,
} from '@wix/editor-platform-sdk-types';
import type { IWixStyleParams } from '@wix/yoshi-flow-editor';

export default function (
  styleParams: IWixStyleParams | StyleParams,
  publicData: TPAPublicData,
) {
  const numbers = (styleParams.numbers as Record<string, number>) || {};

  migrate('coverImageHeight');
  migrate('layoutSpacing');
  migrate('groupCardGridSpacing');
  migrate('listWidgetSpacing');

  // save only new keys that are not undefined
  return {
    numbers: _.omit(numbers, Object.keys(styleParams.numbers || {})),
  };

  function migrate(legacy: string, key = legacy) {
    link(`mobile${_.capitalize(legacy)}`, `${key}▶︎m`);
  }

  function link(legacy: string, key: string) {
    // if target exists or legacy doesn't - skip
    if (numbers[key] !== undefined || numbers[legacy] === undefined) {
      return;
    }

    numbers[key] = numbers[legacy];
  }
}
