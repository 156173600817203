import _ from 'lodash';
import type { UIRouter } from '@wix/tpa-router';

import * as selectors from '../store/selectors';
import type { IRootStore } from '../store/types';

import type { IViewModel } from './types';

export function initializePlatformApi(
  router: UIRouter,
  store: IRootStore,
  vm: IViewModel,
) {
  return {
    router,
    selectors,
    vm: _.omit(vm, '_'),
    getState: () => store.getState(),
  };
}

export type ViewerPlatformApi = ReturnType<typeof initializePlatformApi>;
