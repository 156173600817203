import _ from 'lodash';

import type {
  StyleParams,
  TPAPublicData,
} from '@wix/editor-platform-sdk-types';
import type { IWixStyleParams } from '@wix/yoshi-flow-editor';

export default function (
  styleParams: IWixStyleParams | StyleParams,
  publicData: TPAPublicData,
) {
  const { COMPONENT: settings = {} } = publicData;
  const booleans = (styleParams.booleans as Record<string, boolean>) || {};

  migrate('showMemberCount');
  migrate('showGroupType');
  migrate('showMembersBadges');
  migrate('showShareButton');
  migrate('showReactions');
  migrate('showActivity');
  migrate('showNameOnHover', 'showMediaAuthorOverlay');
  migrate('showFeedTitle');
  migrate('showFeedSubtitle');
  migrate('showCreatePost');
  migrate('showAdminBadge');
  migrate('showImage', 'showGroupImage');
  migrate('showHeaderTitle');
  migrate('showHeaderSubtitle');
  migrate('showSearchSorting');
  migrate('showCreateGroupButton');
  migrate('showGroupsToJoin', 'showSuggestedGroups');
  migrate('showActivity');
  migrate('showButton', 'showGroupButton');

  return {
    booleans: _.omit(booleans, Object.keys(styleParams.booleans || {})),
  };

  function migrate(legacy: string, key = legacy) {
    link(legacy, key);
    link(`${legacy}▶︎m`, `${key}▶︎m`);
    link(`mobile${_.capitalize(legacy)}`, `${key}▶︎m`);
  }

  function link(legacy: string, key: string) {
    if (booleans[key] !== undefined || settings[legacy] === undefined) {
      return;
    }

    booleans[key] = settings[legacy] as boolean;
  }
}
