import _ from 'lodash';
import type { ControllerParams } from '@wix/yoshi-flow-editor';

import migrateEnums from '../settings/__migrations__/enums';
import refactoring from '../settings/__migrations__/global-refactoring';
import migrateNumbers from '../settings/__migrations__/numbers';
import migrateBooleans from '../settings/__migrations__/booleans';
import migrateMobileFontSizes from '../settings/__migrations__/mobile-font-sizes';
import migrateLegacyMobileKeys from '../settings/__migrations__/legacy-mobile-keys';

/**
 * Viewer only
 * will migrate styleParams
 */
export function migrateStyles(params: ControllerParams) {
  const { isViewer } = params.flowAPI.environment;
  const { setProps, config } = params.flowAPI.controllerConfig;

  if (isViewer) {
    setProps(
      params.dangerousStylesOverride(
        _.merge(
          {},
          refactoring(config.style.styleParams, config.publicData),
          migrateEnums(config.style.styleParams, config.publicData),
          migrateNumbers(config.style.styleParams, config.publicData),
          migrateBooleans(config.style.styleParams, config.publicData),
          migrateMobileFontSizes(config.style.styleParams, config.publicData),
          migrateLegacyMobileKeys(config.style.styleParams, config.publicData),
        ),
      ),
    );
  }
}
