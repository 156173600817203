import _ from 'lodash';
import parseColor from 'color';
import type {
  IWixStyleColor,
  IWixStyleFont,
  IWixStyleParams,
} from '@wix/yoshi-flow-editor';
import type {
  StyleParams,
  TPAPublicData,
} from '@wix/editor-platform-sdk-types';

import { EButtonType } from '../wui/button/constants';

export default function (
  styleParams: IWixStyleParams | StyleParams,
  publicData: TPAPublicData,
) {
  const { COMPONENT: settings } = publicData;

  const colors = (styleParams.colors as Record<string, IWixStyleColor>) || {};
  const numbers = (styleParams.numbers as Record<string, number>) || {};
  const fonts = (styleParams.fonts as Record<string, IWixStyleFont>) || {};

  connectColor(colors.createNewPostColor, 'textFieldPlaceholderColor');
  connectColor(colors.createNewPostColor, 'textFieldColor');
  connectColor(colors.postsBackgroundColor, 'cardBackgroundColor');
  connectColor(colors.postsBackgroundColor, 'textFieldBackgroundColor');
  connectColor(colors.postsBorderColor, 'cardBorderColor');
  connectColor(colors.postsBorderColor, 'textFieldBorderColor');

  connectFont(fonts.createNewPostFont, 'textFieldFont');

  connectNumber(numbers.postsBorderWidth, 'cardBorderWidth');
  connectNumber(numbers.cardBorderCorneradius, 'textFieldBorderRadius');

  connectNumber(settings?.buttonType as string, 'buttonType', (value) => {
    if (value === 'rounded') {
      numbers.buttonType = EButtonType.RoundedFilled;
      numbers.secondaryButtonType = EButtonType.RoundedOutlined;
    } else {
      numbers.buttonBorderRadius = 0;
      numbers.secondaryButtonBorderRadius = 0;
      numbers.buttonType = EButtonType.RectangleFilled;
      numbers.secondaryButtonType = EButtonType.RectangleOutlined;
    }
  });

  connectColor(colors.buttonBackground, 'buttonColor');
  connectColor(
    colors.buttonBackground,
    'buttonHoverColor',
    getColorWithOpacity(0.7),
  );

  connectColor(
    colors.buttonTextColor,
    'buttonHoverTextColor',
    getColorWithOpacity(0.7),
  );

  connectColor(
    colors.buttonColor,
    'secondaryButtonColor',
    getColorWithOpacity(0),
  );

  connectColor(colors.buttonColor, 'secondaryButtonTextColor');
  connectColor(colors.buttonColor, 'secondaryButtonBorderColor');
  connectColor(
    colors.buttonColor,
    'secondaryButtonHoverColor',
    getColorWithOpacity(0),
  );
  connectColor(
    colors.buttonColor,
    'secondaryButtonHoverTextColor',
    getColorWithOpacity(0.7),
  );
  connectColor(
    colors.buttonColor,
    'secondaryButtonHoverBorderColor',
    getColorWithOpacity(0.7),
  );

  connectColor(colors.cardBackgroundColor, 'textFieldBackgroundColor');
  connectColor(colors.cardBorderColor, 'textFieldBorderColor');

  // save only new keys
  return {
    colors: _.omit(colors, Object.keys(styleParams.colors || {})),
    numbers: _.omit(numbers, Object.keys(styleParams.numbers || {})),
    fonts: _.omit(fonts, Object.keys(styleParams.fonts || {})),
  };

  function connectColor(
    legacyValue: IWixStyleColor | undefined,
    key: string,
    process?: PostProcessFn<IWixStyleColor>,
  ) {
    connect(colors, legacyValue, key, process);
  }

  function connectNumber(
    legacyValue: number | string | undefined,
    key: string,
    process?: PostProcessFn<number | string>,
  ) {
    connect(numbers, legacyValue, key, process);
  }

  function connectFont(
    legacyValue: IWixStyleFont | undefined,
    key: string,
    process?: PostProcessFn<IWixStyleFont>,
  ) {
    connect(fonts, legacyValue, key, process);
  }
}

type PostProcessFn<T> = (value: T) => T | void;

function connect<T>(
  source: Record<string, T>,
  legacyValue: T | undefined,
  key: string,
  process?: PostProcessFn<T>,
) {
  // if exists & not already migrated - assign legacy value or run process fn
  if (legacyValue !== undefined && source[key] === undefined) {
    if (process) {
      const result = process(legacyValue);

      if (result !== undefined) {
        source[key] = result;
      }
    } else {
      source[key] = legacyValue;
    }
  }
}

const getColorWithOpacity = _.curryRight(function (
  color: IWixStyleColor,
  opacity: number,
): IWixStyleColor {
  return { value: parseColor(color.value).alpha(opacity).string() };
});
